import React, {
  useState,
  useEffect,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav, Dropdown, Modal, Button } from "react-bootstrap";
import { Avatar } from "antd";
import logo from "../../assets/images/vozo-logo-1.png";
import "./TopNavbar.css";
import Add from "@material-ui/icons/Add";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import NewAppointment from "../popups/NewAppointment";
import CreateClient from "../popups/CreateClient";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import AcUnitOutlinedIcon from "@material-ui/icons/AcUnitOutlined";
import { changeFacilitySelect } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";
import { BiLockAlt } from "react-icons/bi";
import { GiUpgrade } from "react-icons/gi";
import { BsCheckCircle } from "react-icons/bs";
import BellIcon from "react-bell-icon";
import styled from "styled-components";
import Notifications from "./Notifications";
import PasswordExpired from "../popups/PasswordExpired";
import { AutoComplete } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import rect from "../../assets/images/Rectangle_761.webp";
import { BsChatLeftText } from "react-icons/bs";
import { Button1 } from "../setting/PatientPortalSettings/StyledComponents";
import { MdOutlineSignalWifiConnectedNoInternet4 } from "react-icons/md";
import Messenger from "../Messages/index";
import { GetMsgList } from "../../StateManagement/Reducers/PatientState";

import {
  getSecureMessageList,
  gettotalUnreadCount,
} from "../../StateManagement/Reducers/MessageState";
import { useSelector } from "react-redux";
import UpgradeRequired from "../popups/UpgradeRequired";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
var CryptoJS = require("crypto-js");

const Prompt = styled.div`
  background: red;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
  right: -2px;
  top: -2px;
  // display: ${(props) => (props.display === "0" ? "none" : "block")};
`;

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};
if (localStorage.getItem("token") === "undefined undefined") {
  localStorage.clear();
  localStorage.setItem("logout", true);
}
const TopNavbar = forwardRef((props, ref) => {
  let history = useHistory();

  const location = useLocation();

  const { pathname } = location;

  let splitLocation = pathname.split("/");

  // ------------------------------ State and Variables Start--------------------------------------------------- //

  let accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");

  const dispatch = useDispatch();
  const [reload, setreload] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [username, setusername] = useState("");
  const [useremail, setuseremail] = useState("");
  const [fornoresults, setnoresult] = useState("");
  const [patimg, setpatimg] = useState("");
  const [refresh, setRefresh] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [modalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [seenTask, setSeenTask] = useState(0);
  const [AppointmentNotification, setAppointmentNotification] = useState([]);
  const [AppointmentSecureMessage, setAppointmentSecureMessage] = useState([]);
  const [NotifyAlert, setNotifyAlert] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [redirectTo1, setRedirectTo1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [id, setid] = useState("0");
  const [countnotify, setCountnotify] = useState("0");
  const [NotifyAlert1, setNotifyAlert1] = useState(false);
  const count = useSelector((state) => state.Message.totalcount);
  const [upgradeNotification, setUpgradeNotification] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlineAlert, setShowOnlineAlert] = useState(false);
  const [facId, setFacId] = useState(
    localStorage.getItem("facility_id") &&
      Decrypt_Value(localStorage.getItem("facility_id"), "vozo")
  );

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized =
    localStorage.getItem("authorized") &&
    Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro =
    localStorage.getItem("main_pro") &&
    Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id =
    localStorage.getItem("owner_id") &&
    Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var storedNames =
    localStorage.getItem("setroles") &&
    Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  var uid =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var ufc =
    localStorage.getItem("facility_id") &&
    Decrypt_Value(localStorage.getItem("facility_id"), "vozo");
  var provarray = localStorage.getItem("user_id") && [
    Decrypt_Value(localStorage.getItem("user_id"), "vozo"),
  ];
  var facarray = localStorage.getItem("facility_id") && [
    Decrypt_Value(localStorage.getItem("facility_id"), "vozo"),
  ];
  const [main_facility, setmain_facility] = useState(
    Decrypt_Value(localStorage.getItem("main_facility"), "vozo")
  );

  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  const expired_days =
    localStorage.getItem("expired_days") &&
    Decrypt_Value(localStorage.getItem("expired_days"), "vozo");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  var testurl = finalurl + "/sites/" + DBHost + "/documentimg/";
  const urlKey = "vozo_schedule_widget";

  const [MsgCount, setMsgCount] = useState(0);
  const [notfyReload, setNotfyReload] = useState(false);
  const [clientAddIs, setClientAddIs] = useState(false);
  const [officeId, setOfficeId] = useState(
    authorized === "1" ? facId : String(main_facility)
  );
  const NewMsgs = useSelector((state) => state.Message.NewMsgs);
  const [proChat, setProChat] = useState(0);
  const [showUpgrade, setShowUpgrade] = useState(false);

  // ------------------------------ State and Variables Start--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //
  const mouseClick = () => {
    setShowOptions(!showOptions);
  };

  const handleMouseLeave = () => setShowOptions(false);

  const ClientAdd = (result) => setClientAddIs(result);
  const Redirect = () => {
    setRedirectTo1(true);
  };

  const moveTo = (value) => {
    if (value === "redirect") {
      setRedirectTo1(false);
    } else if (value === "continue") {
      history.push("/settings/settingpracticeinfo");
      setRedirectTo1(false);
      handleCloseApp();
    }
  };

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    MyPlan();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  const handleInternetWarning = () => {
    setShowOnlineAlert(false);
  };

  const handleShowMesg = () => {
    axiosInstance
      .get("/vozo/appointment/notification/alert?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");
        if (DecodeVal.total !== "0") {
          axiosInstance
            .get("/vozo/appointment/notification/alert/update?uid=" + user_id)
            .then((response) => {})
            .catch((err) => {});
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setNotifyAlert(false);
  };

  const handleCloseApp = () => setShowApp(false);
  const handleShowApp = () => {
    setShowApp(true);
    const facId =
      localStorage.getItem("facility_id") &&
      Decrypt_Value(localStorage.getItem("facility_id"), "vozo");
    setFacId(facId);
    setOfficeId(authorized === "1" ? facId : String(main_facility));
  };

  const saveAlertMsg = (a, b) => {
    setShowApp(false);
    setModalAlerShow(b);
    setModalMsg(a);
  };

  const handleSearchChange = (data) => {
    setSearch(data);
    setnoresult("1");
    setFilteredPatients([]);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleFetchAppointments = () => {
    dispatch(changeFacilitySelect(true));
    setreload(!reload);
    setTimeout(
      () => history.push("/schedule/calender", { Refresh: reload }),
      2000
    );
  };

  const handleSpinneForNewApp = () => {
    console.log("Don't remove this function");
  };
  const generateRandomPassword = (length = 10) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    return Array.from(
      { length },
      () => charset[Math.floor(Math.random() * charset.length)]
    ).join("");
  };
  const handleUpgradePlan = () => {
    axiosInstance
      .get("/vozo/loginemail?uid=" + uid)
      .then((res) => {
        let data = Decrypt_Value(res.data, "vozo");
        const randomPassword = generateRandomPassword();
        const EncryptrandomPassword = Encrypt_Value(randomPassword, "vozo");
        const url = new URL("https://vozohealth.com/pricing");
        url.searchParams.append("token", EncryptrandomPassword);
        url.searchParams.append("email", data["email"]);

        axiosInstance
          .put("/vozo/feedbackloginpass?uid=" + uid, EncryptrandomPassword)
          .then((res) => {
            let data = Decrypt_Value(res.data, "vozo");
          })
          .catch((err) => {
            console.error("there was an error while updating login status");
          });
        window.open(url.toString(), "_blank");
      })
      .catch((err) => {
        console.error("there was an error while updating login status");
      });
  };

  const logout = () => {
    axiosInstance
      .post("/vozo/updatelogout?uid=" + uid)
      .then((response) => {
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      });
  };

  const MyPlan = () => {
    var dr = document.getElementById("remove_show"),
      dr_1 = document.getElementById("remove_show_1");

    dr.classList.remove("show");
    dr_1.classList.remove("show");
  };
  const refreshData = () => {
    const url = window.location.href;
    const arr = url.split("/");
    const item = arr.pop();
    if (item === "patientlist" || (arr[3] === "patient" && arr[4] === "nav")) {
      props.refreshPatient();
    } else {
      history.push("/patient/patientlist");
    }
  };

  const patientDashboard = (props) => {
    let value = props.split("-");
    setSearch(value[0]);
    const currentPath = window.location.pathname;
    if (currentPath.includes("patientdashboard")) {
      history.push("/patient/patientdashboard/" + value[1]);
      window.location.reload();
      // setTimeout(() => setSearch(""), 3000);

      const unlisten = history.listen((location, action) => {
        if (action === "PUSH") {
          setSearch("");
        }
      });
      return () => {
        unlisten();
      };
    } else {
      history.push("/patient/patientdashboard/" + value[1]);
      const unlisten = history.listen((location, action) => {
        if (action === "PUSH") {
          setSearch("");
        }
      });
      return () => {
        unlisten();
      };
    }
  };

  const gotoProfile = () => {
    MyPlan();
    history.push("/settings/settingsmyprofile");
  };
  const successreload = (val) => {
    setAppointmentNotification(val);
  };

  const MsgRefresh = (val) => {
    setAppointmentSecureMessage(val);
  };

  useImperativeHandle(ref, () => ({
    dataFetch() {
      setRefresh((prevState) => !prevState);
    },
  }));

  const handlemsgOpen = () => {
    setShow2(true);
    dispatch(GetMsgList());
    dispatch(gettotalUnreadCount({ uid: uid }));
    setid("0");
  };

  const handleclose = () => {
    setShow2(false);
  };

  const handleClick = () => {
    if (accountType === "-1" || accountType === "1") {
      setShowUpgrade(true);
    } else {
      history.push("/popups/Tasks");
      dispatch(GetNav("iconclose"));
    }
  };
  // ------------------------------ Functions----end------------------------------------------------------ //

  // ------------------------------ useEffect----start---------------------------------------------------- //

  const getData = async () => {
    // await axiosInstance
    //   .get("/vozo/appointment/secure/Message?uid=" + user_id)
    //   .then((response) => {
    //     let DecodeVal = Decrypt_Value(response.data, "vozo");
    //     setAppointmentSecureMessage(DecodeVal);
    //     if (DecodeVal.length > 0) {
    //       var active = 0;
    //       active = DecodeVal.filter(
    //         (item) =>
    //           moment(item.date).format("YYYY-MM-DD") ===
    //             moment().format("YYYY-MM-DD") &&
    //           item.Sender !== "Provider" &&
    //           item.unread !== "0" &&
    //           item.unread !== 0
    //       );

    //       setMsgCount(active.length);
    //       if (active.length > 0 && !props.reqDrop) {
    //         setNotifyAlert(true);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    await axiosInstance
      .get("vozo/appointment/notification?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");
        setAppointmentNotification(DecodeVal);
      })
      .catch((err) => {});

    await axiosInstance
      .get("vozo/appointment/notification/alert?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");

        if (
          DecodeVal.total !== "0" &&
          DecodeVal.total !== 0 &&
          !props.reqDrop
        ) {
          setNotifyAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await getNotify();

    if (props.reqDrop) {
      setNotifyAlert(false);
    }
  };

  const getNotify = async () => {
    axiosInstance
      .get("/vozo/patient/notification/data?type=get&id=0")
      .then((res) => {
        let data = Decrypt_Value(res.data, "vozo");
        setCountnotify(data);
        setMsgCount(data.total_count);
        if (data && data["alert"]) {
          setNotifyAlert1(true);
        } else {
          setNotifyAlert1(false);
        }
      })
      .catch(() => {
        setNotifyAlert1(false);
        setCountnotify([]);
      });
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setShowOnlineAlert(true);
    }
  }, [isOnline]);

  useEffect(() => {
    getData();
  }, [notfyReload, props.reqDrop, refresh]);

  useEffect(() => {
    axiosInstance
      .get("/vozo/task/seen/list?uid=" + user_id)
      .then((res) => {
        setSeenTask(res.data.count);
      })
      .catch((err) => {
        setSeenTask("0");
        console.log(err.message);
      });
  }, [props.tref]);

  useEffect(() => {
    dispatch(getSecureMessageList({ userId: user_id }));
    dispatch(gettotalUnreadCount({ uid: uid }));
  }, [refresh]);

  useEffect(() => {
    axiosInstance
      .get("/sprovider?uid=" + user_id)
      .then((response) => {
        const Decrypt_Value_1 = Decrypt_Value(response.data, "vozo");
        setpatimg(Decrypt_Value_1.img_url);
        setusername(Decrypt_Value_1.fname + " " + Decrypt_Value_1.lname);
        setuseremail(Decrypt_Value_1.email);

        // if (
        //   Decrypt_Value_1?.account_type === 0 &&
        //   !Decrypt_Value_1?.trial_period
        // ) {
        //   var trial_enc = Encrypt_Value(Decrypt_Value_1.trial_period, "vozo");

        //   localStorage.setItem("trial_period", trial_enc);
        //   history.push("/settings/SubscriptionExpired");
        // }
        if ([1, 2].includes(Decrypt_Value_1?.account_update)) {
          axiosInstance
            .post("/vozo/updatelogout?uid=" + uid)
            .then((response) => {
              localStorage.clear();
              localStorage.setItem("logout", true);
              localStorage.setItem("account_update", 1);
              window.location.href = "/";
            })
            .catch((err) => {
              console.log(err);
              localStorage.clear();
              localStorage.setItem("logout", true);
              localStorage.setItem("account_update", 1);
              window.location.href = "/";
            });
        }
      })
      .catch((err) => console.log(err));
  }, [refresh, pathname]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search !== "") {
          let Data_ =
            "limit=10&fname=" +
            search +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            user_id +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id;

          let Data_1 = Encrypt_Value(Data_, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + Data_1)
            .then((response) => {
              let DecodeVal = Decrypt_Value(response.data, "vozo");

              setFilteredPatients(DecodeVal);
              setnoresult(DecodeVal);
            })

            .catch((err) => {
              setFilteredPatients([]);
              setnoresult("");
            });
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  useEffect(() => {
    setProChat(count);
  }, [count]);
  // ------------------------------ useEffect----end---------------------------------------------------
  return (
    <>
      <Navbar bg="light" variant="light" className="top-navbar top-nav-fixed">
        <Navbar.Brand as={Link} to="/schedule/calender" className="nav-logo">
          <img
            src={logo}
            className="d-inline-block align-top vozo-logo"
            alt="vozo-logo"
            height="48px"
          />
        </Navbar.Brand>
        {/* <Nav className="mr-auto vozo-nav " defaultActiveKey="/schedule">
          <NavLink
            to="/schedule/nav/1"
            onClick={props.data}
            className={
              splitLocation[1] === "schedule" || splitLocation[1] === ""
                ? "active nav-menu-st"
                : "nav-menu-st"
            }
            exact
          >
            <CalendarTodayOutlined />
            Schedule
          </NavLink>
          <NavLink
            to="/patient/nav/1"
            onClick={props.data}
            className={
              splitLocation[1] === "patient"
                ? "active nav-menu-st"
                : "nav-menu-st"
            }
            exact
            id="pt_nav-lnk_mnu"
          >
            <PersonAddOutlined />
            Patient
          </NavLink>
          <NavLink
            to="/billing/nav/1"
            onClick={props.data}
            exact
            className={
              splitLocation[1] === "billing"
                ? "active nav-menu-st"
                : "nav-menu-st"
            }
          >
            <AccountBalanceWalletOutlined />
            Billing
          </NavLink>
          <NavLink
            to="/report/nav/1"
            onClick={props.data}
            exact
            className={
              splitLocation[1] === "report"
                ? "active nav-menu-st"
                : "nav-menu-st"
            }
            id="rpt_nav-lnk_anch"
          >
            <ListAltOutlined />
            Reports
          </NavLink>
          <NavLink
            to="/settings/nav/1"
            onClick={props.data}
            exact
            className={
              splitLocation[1] === "settings"
                ? "active nav-menu-st"
                : "nav-menu-st"
            }
          >
            <Settings />
            Settings
          </NavLink>
        </Nav> */}
        <div className="top_nav_display">
          <Button1
            color="white"
            backgroundColor="#2C7BE5"
            className="save_active"
            marginrig="20px"
            family="'Open Sans', sans-serif"
            onClick={() => {
              history.push("/schedule_widget/" + urlKey);
            }}
          >
            Book an Appointment
          </Button1>
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic cal-btn-crate"
              className="save_active top-nav-dropdown  tk-cd-indctr"
              disabled={expired_days < 0}
            >
              <Add />
              Create
            </Dropdown.Toggle>
            {storedNames && (
              <Dropdown.Menu className="drop-down-list">
                {storedNames.demo ? (
                  <Dropdown.Item
                    onClick={handleShow}
                    className="top-nav-dp-list"
                  >
                    <Add />
                    Create Client
                  </Dropdown.Item>
                ) : null}
                {storedNames.appt ? (
                  <Dropdown.Item
                    onClick={handleShowApp}
                    className="top-nav-dp-list"
                    id="appt_crt-ico-nav"
                  >
                    <CalendarTodayOutlined />
                    Create Appointment
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Menu>
            )}
          </Dropdown>
          {owner_id === null && (
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className="top-nav-upgrade-button"
                onClick={handleUpgradePlan}
                disabled={expired_days < 0}
              >
                <GiUpgrade />
                Upgrade Plan
              </Dropdown.Toggle>
            </Dropdown>
          )}
          {/* <Search
          placeholder="Patient Search"
          onResultSelect={(e, data) => patientDashboard(data)}
          onSearchChange={handleSearchChange}
          noResultsMessage={
            fornoresults != "" ? "loading..." : "No Results Found"
          }
          className="top-nav-pat-search"
          results={filteredPatients}
          value={search}
        /> */}
          <div className="for_autoComplete_bi_Patient pat-search-autocomplete top-nv-pat-srch">
            <AiOutlineSearch />
            <AutoComplete
              onClick={mouseClick}
              // onMouseLeave={handleMouseLeave}
              placeholder="Patient Search"
              onSelect={patientDashboard}
              onSearch={handleSearchChange}
              dropdownClassName={search !== "" ? "" : "for_no_loading"}
              notFoundContent={
                showOptions
                  ? fornoresults.length !== 0
                    ? "loading..."
                    : "No Results Found"
                  : ""
              }
              className="top-nav-pat-search"
              id="top-nav-pat-search"
              options={
                showOptions &&
                filteredPatients.map((x) => {
                  return {
                    label: x.title,
                    value: x.title + "-" + x.value,
                  };
                })
              }
              value={search}
              disabled={expired_days < 0}
            />
          </div>
          {storedNames?.mesg && (
            <div className="top-msg-icon">
              {(proChat != "noData" || NewMsgs !== 0) && <Prompt />}
              <Button1
                borderRadius="4px"
                padding=" 5.5px 8px"
                backgroundColor="#ffff"
                color="rgba(46, 46, 46, 1)"
                onClick={handlemsgOpen}
                // disabled={["1", "-1"].includes(accountType) ? "true" : ""}
                disabled={expired_days < 0}
              >
                <BsChatLeftText style={{ width: "16", height: "16px" }} />
              </Button1>
            </div>
          )}

          <>
            {storedNames && storedNames.show_notification && (
              <Dropdown
                show={props.reqDrop}
                onToggle={props.DropReq}
                className="top-nav-rem-pad"
              >
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  disabled={expired_days < 0}
                >
                  <a
                    href="#"
                    onClick={handleShowMesg}
                    className="top-nav-msg-box flex-center"
                  >
                    <BellIcon
                      id="appt-inp_bell"
                      stroke="red"
                      width="20"
                      color={
                        NotifyAlert1
                          ? "#f55151"
                          : NotifyAlert
                          ? "#f55151"
                          : "#212121"
                      }
                      active={NotifyAlert || NotifyAlert1}
                      animate={NotifyAlert || NotifyAlert1}
                      className={
                        NotifyAlert1 || NotifyAlert
                          ? "bell-icon1"
                          : !NotifyAlert || !NotifyAlert1
                          ? "bell-icon"
                          : ""
                      }
                    />
                  </a>
                </Dropdown.Toggle>
                <Dropdown.Menu align="center">
                  <div className="dropdown-menu show app-notification for-tab-style ">
                    <Notifications
                      secMsgData={AppointmentSecureMessage}
                      appData={AppointmentNotification}
                      profileimage={patimg === null ? "" : testurl + patimg}
                      successreload={successreload}
                      MsgRefresh={MsgRefresh}
                      MsgCount={MsgCount}
                      notifyCount={countnotify}
                      refid={props.refid}
                      providerName={username}
                      calRefHandle={props.calRefHandle}
                      show={props.reqDrop}
                      changeDrop={props.DropReq}
                      getNotify={getNotify}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
          <div className="top-nav-add-task">
            {seenTask !== 0 && <Prompt />}
            <div onClick={expired_days < 0 ? "" : handleClick}>
              <Add className="mr-t-5 taskaddcolor" />
            </div>
          </div>

          <Dropdown id="remove_show">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              disabled={expired_days < 0}
            >
              {patimg === null || patimg === "" ? (
                <Avatar
                  size={32}
                  className="topNavBarAvat topnav_popavatar top-nav-bg-col"
                >
                  <span className="topNavBarAvatar top-nav-pro-cap">
                    {username.charAt(0).toUpperCase()}
                  </span>
                </Avatar>
              ) : (
                <Avatar
                  size={32}
                  icon={
                    <img
                      src={testurl + patimg}
                      alt="pic-failed"
                      loading="lazy"
                      width="193px"
                      height="130px"
                    />
                  }
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu id="remove_show_1" className="topnav_card_top">
              <div className="dropdown-menu show card_container topnavcard">
                <div className="ArrowUp"></div>
                <div className="card_top topnav_popup_passdisplay">
                  <div>
                    {patimg === null ? (
                      <Avatar
                        size={40}
                        className="topNavBarAvat topnav_popavatar top-nav-bg-col"
                      >
                        <span className="topNavBarAvatar top-nav-drop-item-icon">
                          {username.charAt(0).toUpperCase()}
                        </span>
                      </Avatar>
                    ) : (
                      <Avatar
                        size={25}
                        icon={
                          <img
                            src={testurl + patimg}
                            alt="pic-failed"
                            loading="lazy"
                            width="193px"
                            height="130px"
                          />
                        }
                      />
                    )}
                    {/* <Avatar in need code
                    size={32}
                    style={{ background: "#A8AEBE", marginTop: "9px" }}
                    className="topNavBarAvat1"
                  >
                    <span className="topNavBarAvatar">
                      {username.charAt(0).toUpperCase()}
                    </span>
                  </Avatar> */}
                  </div>
                  <div className="avatarcard_info navpopname">
                    <div className="app_pro_text_wrap aname" title={username}>
                      <span className=" aname" float="left">
                        {username}
                      </span>
                    </div>

                    <div className="app_pro_text_wrap amail" title={useremail}>
                      <span className=" amail" float="left">
                        {useremail}
                      </span>
                    </div>
                  </div>
                  <div
                    className="avatarcard_edit topnav_avatarcard_"
                    onClick={gotoProfile}
                  >
                    Edit
                  </div>
                </div>

                <hr className="hr.horizline"></hr>

                <div className="planpass">
                  {(owner_id === null || owner_id === " ") && (
                    <NavLink
                      className="plan topnav_popup_passdisplay plan_pass plan_myplan Myplan_alt top-nv-link-color"
                      onClick={MyPlan}
                      to="/settings/myplan"
                    >
                      <AcUnitOutlinedIcon />
                      <div className="ico_word"> My Plan</div>
                    </NavLink>
                  )}
                  <div className="pass  bar topnav_popup_pass plan_pass pass_alt">
                    <div className="lock topnav_popup_passdisplay">
                      <BiLockAlt className="topnav_popup_pass_icon" />
                      <div className=" ico_word" onClick={handleShow1}>
                        Change Password
                      </div>
                    </div>
                  </div>
                </div>

                <div className="log bar" onClick={logout}>
                  Log out
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Modal show={show1} onHide={handleClose1}>
          <PasswordExpired close={handleClose1} />
        </Modal>

        <Modal
          className="client-popup topcreate_popup_centre wid_562"
          centered
          show={show}
          onHide={handleClose}
        >
          <CreateClient
            clientShow={setShow}
            dataFetch={refreshData}
            ClientAdd={ClientAdd}
          />
        </Modal>

        <Modal
          className="app-popup hp new_appt popup_centre  change-top-new-appt"
          centered
          show={showApp}
          onHide={handleCloseApp}
        >
          <Modal.Header className="c-app" closeButton></Modal.Header>

          <NewAppointment
            closepopup={handleCloseApp}
            // start={false}
            provider={authorized === "1" ? uid : main_pro}
            facility={authorized === "1" ? ufc : String(main_facility)}
            provarray={provarray}
            facarray={facarray}
            pat_share={storedNames && storedNames.pat_share}
            savealertmsg={saveAlertMsg}
            popuphide={() => setModalAlerShow(false)}
            FetchAppointments={handleFetchAppointments}
            setspinnerfornewapp={handleSpinneForNewApp}
            officeId={officeId}
            redirect={Redirect}
          />
        </Modal>
        <Modal
          className="user-guide-popup-box"
          show={redirectTo1}
          onHide={() => setRedirectTo1(false)}
        >
          <Modal.Header style={{ borderBottom: "none" }} closeButton>
            <div className="pass-alert-rect">
              <img
                src={rect}
                width="60px"
                height="60px"
                style={{ borderRadius: 8 }}
                alt="ColoredEdge"
              />
            </div>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="expire-modal-heading"
              style={{ fontSize: "19px", marginTop: "0px", marginLeft: "0px" }}
            >
              One Final Step
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p
                className="expire-modal-para"
                style={{
                  color: "#4b4b4b",
                  marginLeft: "0px",
                  fontSize: "16px",
                }}
              >
                Let's Add your Billing Information
              </p>
              <p
                className="expire-modal-para"
                style={{
                  color: "#4b4b4b",
                  marginLeft: "0px",
                  fontSize: "16px",
                }}
              >
                Click continue to add your billing information
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ padding: "20px" }}>
            <Button
              variant="light"
              onClick={() => setRedirectTo1(false)}
              style={{ margin: "0px 20px 0px 0px", padding: "4.5px 8px" }}
            >
              Cancel
            </Button>
            <Button
              style={{ margin: "0px ", padding: "4.5px 8px" }}
              onClick={() => moveTo("continue")}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="alert-popup-message"
          show={modalAlerShow}
          onHide={() => setModalAlerShow(false)}
        >
          <div class="alert msg alert-success" role="alert">
            <BsCheckCircle className="icons-bs" />
            {modalMsg}
          </div>
        </Modal>
        <Modal className="alert-popup-message" show={clientAddIs}>
          <div className="alert msg alert-success" role="alert">
            <BsCheckCircle className="icons-bs" /> Successfully added...
          </div>
        </Modal>
      </Navbar>
      <Modal
        className="alert-popup-message"
        show={showOnlineAlert}
        onHide={handleInternetWarning}
      >
        <div
          className="alert msg alert-danger"
          role="alert"
          onClick={handleInternetWarning}
        >
          <MdOutlineSignalWifiConnectedNoInternet4 className="icons-bs" /> No
          internet. Check your internet connection.
        </div>
      </Modal>

      {show2 && (
        <Messenger
          show2={show2}
          close={handleclose}
          proChatCount={proChat != "noData" ? proChat : 0}
          id={id}
          providerName={username}
          profileimage={patimg === null ? "" : testurl + patimg}
        />
      )}

      {showUpgrade && (
        <UpgradeRequired
          title="Task Management"
          description="View and assign task to other providers based on urgency and to prioritize the task assigned to them"
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={() => setShowUpgrade(false)}
        />
      )}
    </>
  );
});
export default memo(TopNavbar);
