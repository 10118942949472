import { call, put } from "redux-saga/effects";

import {
  SetClinicalReportDetails,
  SetClinicalReportCount,
  SetClinicalReportLoad,
  SetCurrency,
} from "../../StateManagement/Reducers/ReportState";

import {
  ClinicalReportDetails_Api,
  ClinicalReportDetailsCount_Api,
  Currency_Api,
} from "../Apis/ReportApi";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

export function* ClinicalReportDetailsApi({ payload }) {
  try {
    let encrytp_val = Encrypt_Value(payload, "vozo");
    const res = yield call(ClinicalReportDetails_Api, encrytp_val);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      if (payload.offset === 0) {
        yield put(SetClinicalReportDetails(temp_role));
      } else {
        yield put(SetClinicalReportLoad(temp_role));
      }
    }
  } catch (e) {
    if (payload.offset === 0) {
      yield put(SetClinicalReportDetails([]));
    } else {
      console.log(e.message);
    }
  }
}

// export function* ClinicalReportCountApi() {
//   try {
//     const res = yield call(ClinicalReportDetailsCount_Api);
//     if (res.status === 200) {
//       let temp_role = Decrypt_Value(res.data, "vozo");
//       yield put(SetClinicalReportCount(temp_role));
//     }
//   } catch (e) {
//     yield put(SetClinicalReportCount([]));
//     console.log(e.message);
//   }
// }

export function* CurrencyApi() {
  try {
    const res = yield call(Currency_Api);
    if (res.status === 200) {
      let temp_role = Decrypt_Value(res.data, "vozo");
      yield put(SetCurrency(temp_role[0].currency));
    }
  } catch (e) {
    yield put(SetCurrency([]));
    console.log(e.message);
  }
}
