import { all } from "redux-saga/effects";

import {
  RevisionSaga,
  FacilitySearchDataSaga,
  AppointmentSearchDataSaga,
  NoteUpdateSaga,
  GetIncomingFaxSaga,
  SearchIncomingFaxSaga,
  InsertIncomingFaxSaga,
  GetOutgoingFaxSaga,
  InsertOutgoingFaxSaga,
  ProvFaxDataSaga,
  SaveFaxQueueSaga,
  EmptyProviderListSaga,
  IncomingFaxSaga,
  OutgoingFaxSaga,
  IncomingFaxAssignSaga,
  IncomingFaxProviderSaga,
  PreviewDownloadSagaM,
  PreviewDownload_2out_Saga,
  EmptyProviderListReferalSaga,
  RefferalListSaga,
  PatListSaga,
  ProvidersListSaga,
  ReferalDataSaga,
  Refmangmt_Preview_details,
  ProviderSearchSaga,
  ReferrerDiagnosisSaga,
  PatientDetailsSaga,
  ReferralInsertSaga,
  SingleReferralSaga,
  UpdateReferralSaga,
  ReferalDeleteSaga,
  ReferalContactSaga,
  ReferalContactInsertSaga,
  ReferalContactUpdateSaga,
  ReferalContactDeleteSaga,
  ReferalContactSingleSaga,
  RefContactemailcheckSaga,
  RefContactemptyvalidationSaga,
  RefContactphonecheckSaga,
  RefContactnpicheckSaga,
  RefContactfaxcheckSaga,
  RefContactemptyphoneSaga,
  RefContactemptyfaxSaga,
  RefContactemptynpiSaga,
  SendFaxMailSaga,
  ReferralDataOneSaga,
  ReferralDownloadSaga,
  EmptyDownloadSaga,
  ReferralPreviewSaga,
  DeleteTempSaga,
  ProvidersFaxMailSaga,
  ProviderListSaga,
} from "./Patient/PatientSagaWatchers";
import {
  AuditLogSaga,
  PatientSearchDataSaga,
  UserSearchDataSaga,
  BillingFacilitySaga,
  PracticeDashboardSaga,
  DeleteInvSaga,
  TotalInvoiceSage,
  AddPaymentAmtSaga,
} from "./Schedule/ScheduleSagaWatchers";

import {
  ClinicReportDetails,
  // ClinicReportCount,
  Currency,
} from "./Reports/ReportSagaWatchers";

import {
  PatEduSaga,
  PatTagsSaga,
  FilterPatEdu,
  AssignResourceSaga,
  AlertEduSaga,
} from "./DemographicsHomework/DemoHomeworkWatchers";
import {
  AddDrugDataSaga,
  GetManagementDrugsSaga,
  GetEditDrugsSaga,
  GetCPT4CodesSaga,
  GetDestroyedDrugsSaga,
  DestroyDrugDataSaga,
} from "./Setting/SettingSagaWatchers";
import {
  MessagerListSaga,
  MsgSaga,
  PreviewDownloadSaga,
  SecureMessageListSaga,
  SecureMessageSearchSaga,
  TotalUnreadCountSaga,
  UnreadCountUpdateSaga,
} from "./Message/MessageSagaWatcher";
import {
  AccountVerificationSaga,
  TemporaryResSaga,
} from "./SubscriptionRenewal/SubscriptionRenewalSagaWatcher";
import { SendUserOtpSaga } from "./Prelogin/PreloginWatchers";
import {
  providerListSaga,
  bookScheduleSaga,
} from "./ScheduleWidget/WidgetSagaWatcher";

export default function* rootSaga() {
  return yield all([
    RevisionSaga(),
    PatEduSaga(),
    PatTagsSaga(),
    FilterPatEdu(),
    AssignResourceSaga(),
    AlertEduSaga(),
    AuditLogSaga(),
    PatientSearchDataSaga(),
    UserSearchDataSaga(),
    FacilitySearchDataSaga(),
    AppointmentSearchDataSaga(),
    NoteUpdateSaga(),
    ClinicReportDetails(),
    // ClinicReportCount(),
    BillingFacilitySaga(),
    PracticeDashboardSaga(),
    Currency(),
    DeleteInvSaga(),
    TotalInvoiceSage(),
    AddPaymentAmtSaga(),
    AddDrugDataSaga(),
    DestroyDrugDataSaga(),
    GetManagementDrugsSaga(),
    GetEditDrugsSaga(),
    GetCPT4CodesSaga(),
    GetDestroyedDrugsSaga(),
    ProvFaxDataSaga(),
    SaveFaxQueueSaga(),
    EmptyProviderListSaga(),
    GetIncomingFaxSaga(),
    SearchIncomingFaxSaga(),
    InsertIncomingFaxSaga(),
    GetOutgoingFaxSaga(),
    InsertOutgoingFaxSaga(),
    ProvFaxDataSaga(),
    EmptyProviderListSaga(),
    IncomingFaxSaga(),
    OutgoingFaxSaga(),
    IncomingFaxAssignSaga(),
    IncomingFaxProviderSaga(),
    PreviewDownloadSagaM(),
    PreviewDownload_2out_Saga(),
    EmptyProviderListReferalSaga(),
    ProvidersListSaga(),
    PatListSaga(),
    RefferalListSaga(),
    ReferalDataSaga(),
    Refmangmt_Preview_details(),
    ProviderSearchSaga(),
    ReferrerDiagnosisSaga(),
    PatientDetailsSaga(),
    ReferralInsertSaga(),
    SingleReferralSaga(),
    UpdateReferralSaga(),
    ReferalDeleteSaga(),
    ReferalContactSaga(),
    ReferalContactInsertSaga(),
    ReferalContactUpdateSaga(),
    ReferalContactDeleteSaga(),
    ReferalContactSingleSaga(),
    RefContactemailcheckSaga(),
    RefContactemptyvalidationSaga(),
    RefContactphonecheckSaga(),
    RefContactnpicheckSaga(),
    RefContactfaxcheckSaga(),
    RefContactemptyphoneSaga(),
    RefContactemptyfaxSaga(),
    RefContactemptynpiSaga(),

    SendFaxMailSaga(),
    ReferralDataOneSaga(),
    ReferralDownloadSaga(),
    EmptyDownloadSaga(),
    ReferralPreviewSaga(),
    DeleteTempSaga(),
    ProvidersFaxMailSaga(),
    MsgSaga(),
    MessagerListSaga(),
    PreviewDownloadSaga(),
    ProviderListSaga(),
    UnreadCountUpdateSaga(),
    TotalUnreadCountSaga(),
    SecureMessageListSaga(),
    SecureMessageSearchSaga(),
    AccountVerificationSaga(),
    TemporaryResSaga(),
    SendUserOtpSaga(),
    providerListSaga(),
    bookScheduleSaga(),
  ]);
}
